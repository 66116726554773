var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-scrollbar',{staticStyle:{"border-width":"0px"},attrs:{"wrap-style":"max-height: 400px;padding:13px;"}},_vm._l((_vm.newRepeatableTemplateData),function(column){return _c('div',{key:column.label,staticClass:"d-flex flex-column justify-content-center",attrs:{"prop":column.key}},[(column.inputType == 'DATE')?_c('div',[_c('h5',{staticClass:"kanban-card-content"},[_vm._v(" "+_vm._s(column.label + " " + (column.is_primary ? "*" : ""))+" ")]),_c('el-date-picker',{staticStyle:{"width":"100%"},attrs:{"placeholder":"Select date","format":_vm.getDefaultDateFormat &&
            _vm.getDefaultDateFormat.includes('Do') == false
              ? _vm.getDefaultDateFormat
                  .replace('YYYY', 'yyyy')
                  .replace('DD', 'dd')
              : 'MM-dd-yyyy'},model:{value:(_vm.selectedRow[column.key]),callback:function ($$v) {_vm.$set(_vm.selectedRow, column.key, $$v)},expression:"selectedRow[column.key]"}})],1):(
          column.inputType == 'SELECT' || column.inputType == 'MULTI_SELECT'
        )?_c('div',[_c('h5',{staticClass:"kanban-card-content"},[_vm._v(" "+_vm._s(column.label + " " + (column.is_primary ? "*" : ""))+" ")]),_c('el-select',{staticStyle:{"width":"100%"},attrs:{"placeholder":"Please select...","clearable":true,"filterable":true,"multiple":column.inputType == 'MULTI_SELECT' ? true : false,"allow-create":true,"default-first-option":true,"disabled":(column.inputType == 'SELECT' ||
              column.inputType == 'MULTI_SELECT') &&
            column.options &&
            !column.options.length},model:{value:(_vm.selectedRow[column.key]),callback:function ($$v) {_vm.$set(_vm.selectedRow, column.key, $$v)},expression:"selectedRow[column.key]"}},_vm._l((column.options),function(op){return _c('el-option',{key:op,attrs:{"label":op,"value":op}})}),1)],1):(column.inputType == 'CHECKBOX')?_c('div',[_c('h5',{staticClass:"kanban-card-content"},[_vm._v(" "+_vm._s(column.label + " " + (column.is_primary ? "*" : ""))+" ")]),_c('el-checkbox',{model:{value:(_vm.selectedRow[column.key]),callback:function ($$v) {_vm.$set(_vm.selectedRow, column.key, $$v)},expression:"selectedRow[column.key]"}})],1):(column.inputType == 'ENTITY')?_c('div',[_c('h5',{staticClass:"kanban-card-content"},[_vm._v(" "+_vm._s(column.label + " " + (column.is_primary ? "*" : ""))+" ")]),_c('EntityExecute',{attrs:{"data":column,"form":_vm.selectedRow,"is-view":false,"fieldsData":_vm.newRepeatableTemplateData,"fromRepeatable":true,"document_id":_vm.document_id ? _vm.document_id : null,"company_id":_vm.company_id ? _vm.company_id : null},on:{"entityDataUpdated":_vm.emitDataToEntityVariables,"clearEntityFields":_vm.emitUnsetEntityVariables}})],1):(column.inputType == 'CURRENCY')?_c('div',[_c('h5',{staticClass:"kanban-card-content"},[_vm._v(" "+_vm._s(column.label + " " + (column.is_primary ? "*" : ""))+" ")]),_c('currency-input',{staticClass:"field-textarea currency-input",attrs:{"currency":column.validations && column.validations.currency
              ? column.validations.currency
              : 'USD'},model:{value:(_vm.selectedRow[column.key]),callback:function ($$v) {_vm.$set(_vm.selectedRow, column.key, $$v)},expression:"selectedRow[column.key]"}})],1):(column.inputType == 'CHECKBOX_GROUP')?_c('div',[_c('h5',{staticClass:"kanban-card-content"},[_vm._v(" "+_vm._s(column.label + " " + (column.is_primary ? "*" : ""))+" ")]),_c('checkboxGroupExecute',{attrs:{"data":column,"form":_vm.selectedRow,"fromRepeatable":true,"document_id":_vm.document_id ? _vm.document_id : null,"company_id":_vm.company_id ? _vm.company_id : null}})],1):(column.inputType == 'FORMULA')?_c('div',[_c('h5',{staticClass:"kanban-card-content"},[_vm._v(" "+_vm._s(column.label + " " + (column.is_primary ? "*" : ""))+" ")]),_c('FormulaExecute',{attrs:{"data":column,"form":_vm.selectedRow,"fromRepeatable":true,"document_id":_vm.document_id ? _vm.document_id : null,"company_id":_vm.company_id ? _vm.company_id : null}})],1):_c('div',[_c('h5',{staticClass:"kanban-card-content"},[_vm._v(" "+_vm._s(column.label + " " + (column.is_primary ? "*" : ""))+" ")]),_c('el-input',{attrs:{"type":"text"},model:{value:(_vm.selectedRow[column.key]),callback:function ($$v) {_vm.$set(_vm.selectedRow, column.key, $$v)},expression:"selectedRow[column.key]"}})],1),_c('br')])}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }